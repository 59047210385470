<template>
  <div my-info>
    <div v-if="isFetched && config" v-for="(item, idx) in list" class="info-router-group" :key="idx">
      <h3>{{ $t(item.title) }}</h3>
      <MenuLinkButton v-for="child in item.children" @click="move(child)" :name="getItemLabel(child.label)" :is-badge="hasItemBadge(child)" :badge-type="badgeType(child)" :hidden-right="structure.isSelfExclusion || hasRightButton(child)" />
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import MenuLinkButton from '@/views/components/gg-pass/MenuLinkButton.vue';
import { getSiteList } from '@/constants/model/responsible-gaming';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import ResponsibleHeader from '@/views/components/pages/responsible-gaming/template/common/ResponsibleHeader.vue';
import { kycVerified, useLugasSiteIds } from '@/constants/base/responsible-gaming';

export default {
  name: 'MyInfo',
  lexicon: 'responsibleGaming',
  components: { ResponsibleHeader, MenuLinkButton, FontIcon },
  props: {
    config: Specific,
    structure: Specific,
  },
  data() {
    return {
      list: [],
      isFetched: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    isEnabledLugas() {
      return useLugasSiteIds.indexOf(this.site) >= 0;
    },
    isKycVerified() {
      const { KycStatus } = this.userInfo;
      return kycVerified.indexOf(KycStatus) >= 0;
    },
  },
  methods: {
    async initialize() {
      this.list = getSiteList(this.config, (this.isEnabledLugas && !this.isKycVerified));
      this.isFetched = true;
    },
    move(item) {
      if (this.structure.isSelfExclusion) return;
      const requested = item.requestKey ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey ? this.config[item.verifyFreezeKey] : false;
      if (requested && verifyFreeze) return;
      else this.replaceRouteName(item.name);
    },
    getItemLabel(item) {
      let defaultString = '_.title.';
      if (item.hasOwnProperty(this.site)) {
        defaultString += item[this.site];
      } else defaultString += item.default;

      return this.$t(defaultString);
    },
    hasItemBadge(item) {
      const requested = item.requestKey !== '' ? this.config[item.requestKey] : false;
      return requested;
    },
    hasRightButton(item) {
      const requested = item.requestKey ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey ? this.config[item.verifyFreezeKey] : false;
      if (requested && verifyFreeze) return true;
      return false;
    },
    badgeType(item) {
      const requested = item.requestKey !== '' ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey !== '' ? this.config[item.verifyFreezeKey] : false;

      if (!verifyFreeze && requested) return 'REQUEST';
      else if (verifyFreeze && requested) return 'APPROVAL';
    },

  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info] {
  .info-router-group { .pt(10); .flex(); .flex-dc(); .gap(10); .ph(20);
    > h3 {.c(@c-b08); .fs(16)}
  }
  .list-holder {
    > a { .block(); .p(16, 24, 12, 16); .-b(@c-b01); .c(@c-b08); .bold(); .rel();
      [font-icon] { .bold(); .c(@c-b05); .rt(0, 50%); .abs(); .t-yc(); }
    }
  }
}
</style>

